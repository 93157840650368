import React from "react";
import { Seo } from "../Layout/Seo";

const Page = ({ pageContext, lang }) => {
  return (
    <Seo
      title={pageContext.page.title}
      description={pageContext.page.seoDescription}
      lang={lang}
    >
      <div id="pageSimpel">
        <h1>{pageContext.page.title}</h1>

        <section className="section-block">
          <div
            dangerouslySetInnerHTML={{ __html: pageContext.page.body }}
            className="pageSimpelContent"
          />
        </section>
      </div>
    </Seo>
  );
};

export default Page;
